<template>
  <div class="Question">
    <div class="ques-header">
      <div class="ques-header-top">
        <div class="title">
          <div class="oblong"></div>
          <div>问题列表</div>
        </div>
        <div class="btn" @click="createQues">新增问题</div>
        <div class="btn group" @click="toQuestionGroup">问题组管理</div>
      </div>
      <div class="ques-header-bottom">
        <div class="ques-header-bottom-left">
          <a-form-model
            :model="form"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            :colon="false"
          >
            <a-form-model-item>
              <a-input
                v-model.trim="form.name"
                placeholder="请输入问题名称关键词"
                @pressEnter="search"
              />
            </a-form-model-item>
          </a-form-model>
          <div @click="search" class="search">查询</div>
        </div>
        <div class="ques-header-bottom-right" v-show="isTemp">
          <div
            @click="reset"
            :style="{
              background: selectedRowKeys.length ? '#bfc1c6' : '#dfe0e2',
            }"
          >
            重置
          </div>
          <!-- <div class="add">添加</div> -->
          <div
            class="finish"
            @click="finish"
            :style="{
              background: selectedRowKeys.length ? '#83ba7b' : '#8ed79f',
            }"
          >
            完成
          </div>
          <!-- <div>批量操作</div>
          <div
            class="del"
            :style="{
              background: selectedRowKeys.length ? '#dc3333' : '#e99e9e',
            }"
            @click="del(ids)"
          >
            删除
          </div> -->
        </div>
      </div>
    </div>
    <div class="ques-main" ref="quesMain">
      <a-table
        :columns="isTemp ? columns2 : columns1"
        :data-source="computedData"
        :loading="loading"
        :row-selection="isTemp ? rowSelection : undefined"
        :rowKey="(record) => record.id"
        :pagination="pagination"
        :scroll="{ y: maxHeight }"
      >
        <!-- 问题组名称 -->
        <span slot="customGroupName">
          <a-select v-model="groupName" @change="groupNameChange">
            <a-select-option
              :value="item.id"
              v-for="(item, index) in questionGroupList"
              :key="index"
              >{{ item.name }}
            </a-select-option>
          </a-select>
        </span>
        <!-- tag筛选 -->
        <span slot="tag">
          <a-select v-model="tag" @change="tagChange">
            <a-select-option
              :value="item.id"
              v-for="(item, index) in tagList1"
              :key="index"
              >{{ item.name }}
            </a-select-option>
          </a-select>
        </span>
        <div slot="judgment" slot-scope="judgment">
          {{ judgment ? judgment : "/" }}
        </div>
        <div slot="tags" slot-scope="tags" class="tags">
          <template v-if="tags.length">
            <div
              :style="{ color: item.color }"
              v-for="item in tags"
              :key="item.id"
            >
              {{ item.name }}
            </div>
          </template>

          <div v-else class="text">/</div>
        </div>
        <span slot="type" slot-scope="type">
          {{ type === "SELECT" ? "单选" : "录入" }}
        </span>
        <span slot="imgNeed" slot-scope="imgNeed">
          <span v-if="imgNeed == 0">禁止</span>
          <span v-if="imgNeed == 1">允许</span>
          <span v-if="imgNeed == 2">必须</span>
        </span>
        <span slot="manual" slot-scope="manual, record">
          <span v-if="record.type == 'SELECT'">{{
            manual ? "手动" : "自动"
          }}</span>
          <span v-else>/</span>
        </span>
        <div
          slot="tagList"
          slot-scope="tagList"
          class="tag-list"
          :style="{ height: maxHeight + 'px' }"
        >
          <div
            v-for="(item, index) in tagList"
            :key="item.id"
            :style="{
              background: getColor(item.id) ? item.color : '#fff',
              color: getColor(item.id) ? '#fff' : item.color,
              border: getColor(item.id) ? 'none' : '1px solid #000',
              borderColor: getColor(item.id) ? 'none' : item.color,
            }"
            @click="clickTag(item.id, index)"
          >
            {{ item.name }}
          </div>
        </div>

        <div slot="action" slot-scope="item" class="action">
          <div @click="look(item.id)">查看</div>
          <div class="del" @click="del(item.id)">删除</div>
        </div>
      </a-table>
      <div
        class="tag-box"
        :style="{ height: maxHeight + 'px' }"
        v-show="isTemp"
      >
        <div
          v-for="(item, index) in tagList"
          :key="item.id"
          :style="{
            background: getColor(item.id) ? item.color : '#fff',
            color: getColor(item.id) ? '#fff' : item.color,
            border: getColor(item.id) ? 'none' : '1px solid #000',
            borderColor: getColor(item.id) ? 'none' : item.color,
          }"
          @click="clickTag(item.id, index)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <create-quse-modal
      :key="componentKey"
      ref="createQuseModal"
      :visible="visible"
      @ok="createQuseOk"
      @cancel="createQuseCancel"
    ></create-quse-modal>
    <div ref="handleRefModel" class="handle-modal">
      <a-modal
        v-model="delVisible"
        title="操作确认"
        @ok="handleOk"
        :maskClosable="false"
        centered
        width="395px"
        :getContainer="() => $refs.handleRefModel"
        cancelText="否"
        okText="是"
      >
        <div>确认删除所选问题？</div>
      </a-modal>
    </div>
  </div>
</template>

<script>
import {
  addQuestion,
  getQuestion,
  delQuestion,
  getQuestionGroup,
} from "../common/api/questions";
import CreateQuseModal from "../components/CreateQuesModal.vue";
import { getTag } from "../common/api/tag";

export default {
  name: "Question",
  components: {
    CreateQuseModal,
  },
  data() {
    return {
      maxHeight: 0,
      isTag: false,
      groupName: "问题组名称",
      tag: "带有的Tag",
      componentKey: 0,
      isTemp: false,
      fromName: "",
      selectedRows: [],
      selectedRowKeys: [],
      activeId: [],
      copyArr: [],
      tagList: [],
      tagList1: [],

      // 删除问题id
      ids: "",
      // 是否显示删除弹窗
      delVisible: false,
      loading: false,
      params: {
        page: 0,
        size: 1000,
        name: "",
      },
      form: {
        page: 0,
        size: 5,
        name: "",
        tagIds: "",
        tid: "",
      },
      visible: false,

      quesList: [],
      columns: [],
      columns1: [
        {
          title: "问题ID",
          dataIndex: "id",
          key: "id",
          align: "center",
        },
        {
          slots: { title: "customGroupName" },
          dataIndex: "groupName",
          width: 150,
          key: "groupName",
          align: "center",
        },
        {
          title: "问题名称",
          dataIndex: "name",
          width: 300,
          key: "name",
          align: "center",
        },
        {
          title: "问题详情",
          dataIndex: "judgment",
          key: "judgment",
          scopedSlots: { customRender: "judgment" },
          align: "center",
        },
        {
          dataIndex: "tags",
          key: "tags",
          width: 150,
          slots: { title: "tag" },
          scopedSlots: { customRender: "tags" },
          align: "center",
        },
        {
          title: "应答类型",
          key: "type",
          dataIndex: "type",
          scopedSlots: { customRender: "type" },
          align: "center",
        },
        {
          dataIndex: "imgNeed",
          key: "imgNeed",
          title: "是否添加图片",
          scopedSlots: { customRender: "imgNeed" },
          align: "center",
        },
        {
          dataIndex: "manual",
          key: "manual",
          title: "评分方式",
          scopedSlots: { customRender: "manual" },
          align: "center",
        },

        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
          align: "center",
        },
      ],
      columns2: [
        {
          title: "问题ID",
          dataIndex: "id",
          key: "id",
          align: "center",
        },
        {
          slots: { title: "customGroupName" },
          dataIndex: "groupName",
          width: 150,
          key: "groupName",
          align: "center",
        },
        {
          title: "问题名称",
          dataIndex: "name",
          // width: 300,
          key: "name",
          align: "center",
        },
        {
          title: "问题详情",
          dataIndex: "judgment",
          key: "judgment",
          scopedSlots: { customRender: "judgment" },
          align: "center",
        },
        {
          title: "带有的Tag",
          dataIndex: "tags",
          key: "tags",
          scopedSlots: { customRender: "tags" },
          align: "center",
        },
        {
          title: "Tag筛选",
          dataIndex: "tagList",
          key: "tagList",
          align: "center",
          scopedSlots: { customRender: "tagList" },
          width: 300,
          customCell: (record, rowIndex) => {
            // console.log(record, rowIndex);
            if (rowIndex === 0) {
              return {
                attrs: {
                  rowSpan: this.quesList.length,
                },
              };
            } else {
              return {
                style: { display: "none" },
              };
            }
          },
        },
      ],
      labelCol: { span: 9 },
      wrapperCol: { span: 14, offset: 1 },
      questionGroupList: [],

      // 分页配置对象
      pagination: {
        position: "bottom",
        pageSize: 5,
        current: 1,
        total: 0,
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ["5", "10", "20"], // 每页数量选项
        showQuickJumper: true,
        // 显示总数
        showTotal: (total) => `共 ${total} 条`,
        // 改变每页数量时更新显示
        onShowSizeChange: (page, pageSize) => this.onSizeChange(page, pageSize),
        // 点击页码事件
        onChange: (page, pageSize) => this.onPageChange(page, pageSize),
      },
      // 列表项配置对象
      // rowSelection: {
      //   onChange: (selectedRowKeys, selectedRows) =>
      //     this.onRowChange(selectedRowKeys, selectedRows),
      // },

      // 改变每页数量时更新显示
      onSizeChange(page, pageSize) {
        console.log("每页数量:", pageSize);
        this.pagination.current = 1;
        this.pagination.pageSize = pageSize;
        this.form.page = 0;
        this.form.size = pageSize;
        this.getQuestionList();
      },
      // 点击页码事件
      onPageChange(page) {
        console.log("页码值:", page);
        this.pagination.current = page;
        this.form.page = page - 1;
        this.getQuestionList();
      },
    };
  },
  methods: {
    // 获取问题组列表
    async getQuestionGroupList() {
      const res = await getQuestionGroup(this.params);
      console.log(res, "问题组");
      let arr = res.data.content;
      arr.unshift({ name: "不限", id: "" });
      this.questionGroupList = arr;
    },
    reset() {
      this.selectedRows = [];
      this.selectedRowKeys = [];
    },
    finish() {
      if (!this.selectedRowKeys.length) {
        this.$message.warn("请先选择问题");
      } else {
        this.$store.commit("updateTempQuestions", this.selectedRows);
        this.$emit("questionToTemplate");
        this.selectedRowKeys = [];
        this.selectedRows = [];
      }
    },
    clickTag(id, i) {
      console.log(id);
      this.isTag = true;
      this.copyArr = [...this.activeId];
      if (this.copyArr.includes(id) && this.copyArr.length == 1) {
        this.$message.info("至少选择一个Tag");
        return;
      }
      if (this.copyArr.includes(id)) {
        if (id) {
          const index1 = this.copyArr.findIndex((v) => v == id);
          console.log(index1);
          this.copyArr.splice(index1, 1);
          // const index2 = this.copyArr.findIndex((v) => v == "");
          // if (index2 !== -1) {
          //   this.copyArr.splice(index2, 1);
          // }
        }
      } else {
        if (id) {
          this.copyArr.push(id);
          if (this.copyArr.includes("")) {
            this.copyArr.splice(0, 1);
          }
          // if (this.copyArr.length == this.tagList.length - 1) {
          //   this.copyArr = [""];
          // }
        } else {
          this.copyArr = [""];
        }
      }
      console.log(this.copyArr, 5555);

      const index = this.copyArr.findIndex((v) => v === "");
      if (index == -1) {
        this.form.tagIds = this.copyArr.join(",");
      } else {
        delete this.form.tagIds;
      }

      this.form.page = 0;
      this.pagination.current = 1;
      this.getQuestionList();
    },
    async handleOk() {
      console.log(this.ids);
      const res = await delQuestion({ ids: this.ids });
      console.log(res);
      this.$message.success(res.data);
      if (this.quesList.length === 1 && this.form.page !== 0) {
        this.form.page--;
        this.pagination.current--;
      }

      this.delVisible = false;

      this.getQuestionList();
    },

    // 获取问题列表
    async getQuestionList() {
      let copyForm = JSON.parse(JSON.stringify(this.form));
      copyForm.page = copyForm.page.toString();
      for (let k in copyForm) {
        if (!copyForm[k]) {
          delete copyForm[k];
        }
      }
      this.loading = true;
      const res = await getQuestion(copyForm);
      this.loading = false;
      console.log(res);
      if (this.isTag) {
        if (res.data.totalElements) {
          this.activeId = this.copyArr;
        } else {
          return this.$message.info("暂无数据！请重新选择Tag");
        }
        this.isTag = false;
      }
      this.quesList = res.data.content;
      this.pagination.total = res.data.totalElements;
    },
    // 获取Tag列表
    async getTagList() {
      const res = await getTag({
        page: 0,
        size: 1000,
      });
      console.log("tag", res);
      let arr = res.data.content;
      this.tagList = [
        {
          color: "#8DE580",
          name: "所有",
          id: "",
        },
        ...arr,
      ];
      this.tagList1 = [
        {
          color: "#8DE580",
          name: "不限",
          id: "",
        },
        ...arr,
      ];
      this.activeId = [];
      this.activeId = [""];
    },
    toQuestionGroup() {
      this.$router.push("/questiongroup");
    },
    async createQuseOk(params) {
      const res = await addQuestion(params);
      console.log(res);
      this.$message.success(res.msg);
      this.form.page = 0;
      this.pagination.current = 1;
      this.getQuestionList();

      this.$refs.createQuseModal.createQuesForm = {
        itemGroupId: undefined,
        tagsId: [],
        name: "",
        judgment: "",
        imgDemo: "",
        manual: false,
        imgNeed: 0,
        answerNeed: false,
        numberMax: "",
        numberMin: "",
        optionsList: [
          {
            val: "",
            score: "",
          },
        ],
        reasonList: [
          {
            val: "",
          },
        ],
        scoresList: [
          {
            val: "",
            score: "",
          },
        ],
        typeId: 2,
        size: "",
        unit: "",
        number: false,
        manualMax: "",
        manualMin: "",
      };
      this.$refs.createQuseModal.fileList = [];
      this.$refs.createQuseModal.typeShow = false;
      this.$refs.createQuseModal.charsShow = false;

      this.visible = false;
    },
    createQuseCancel() {
      this.visible = false;
    },
    createQues() {
      this.visible = true;
    },
    // 选中项发生变化时的回调
    onRowChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      if (selectedRowKeys.length === selectedRows.length)
        this.selectedRows = selectedRows;
      else {
        this.selectedRows = selectedRowKeys.map(
          (key) =>
            this.selectedRows.find((row) => row.id === key) ||
            selectedRows.find((row) => row.id === key) //必在两者之中
        );
      }

      console.log(this.selectedRowKeys, this.selectedRows);
    },

    groupNameChange(e) {
      if (e == "") {
        this.groupName = "问题组名称";
        this.form.tid = "";
      } else {
        this.form.tid = e;
      }
      this.form.page = 0;
      this.getQuestionList();
    },
    tagChange(e) {
      if (e == "") {
        this.tag = "带有的Tag";
        this.form.tagIds = "";
      } else {
        this.form.tagIds = e;
      }
      this.form.page = 0;
      this.getQuestionList();
    },
    // 查询
    search() {
      this.form.page = 0;
      this.pagination.current = 1;
      console.log(this.form);
      this.getQuestionList();
    },
    look(id) {
      this.$router.push({
        path: "/questiondetails",
        query: {
          id,
        },
      });
    },
    del(id) {
      this.ids = id;
      this.delVisible = true;
    },
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onRowChange,
        // getCheckboxProps: (record) => ({
        //   props: {
        //     disabled: true, // 这里控制多选框的显示与隐藏
        //   },
        // }),
      };
    },
    computedData() {
      return this.quesList.map((item) => ({ ...item, tagList: this.tagList }));
    },

    getColor() {
      return (id) => {
        // console.log(id, "颜色id");
        if (this.activeId.includes(id)) {
          return true;
        } else {
          return false;
        }
      };
    },
  },
  async created() {
    this.$store.commit("updateIsKeepAlive", false);

    await this.getTagList();
    this.getQuestionList();
    this.getQuestionGroupList();
  },
  mounted() {
    this.$nextTick(() => {
      this.maxHeight = this.$refs.quesMain.clientHeight - 130;
    });
  },
  beforeRouteEnter(to, from, next) {
    console.log(from.name);

    next((vm) => {
      let isKeepAlive = vm.$store.state.isKeepAlive;

      if (
        (from.name == "Questiondetails" && isKeepAlive) ||
        (from.name == "Createtemplate" && isKeepAlive)
      ) {
        to.meta.keepAlive = false;
      } else {
        to.meta.keepAlive = true;
      }
      vm.fromName = from.name;
    });
  },
  async activated() {
    console.log(this.$store.state.refreshPath, "刷新列表");
    document.getElementsByClassName("ant-table-body")[0].style.height = "";

    let index = this.$store.state.refreshPath.findIndex((v) => {
      return v == "question";
    });
    console.log(index, "刷新列表");
    if (index != -1) {
      if (this.fromName == "Createtemplate") {
        document.getElementsByClassName("ant-table-body")[0].style.height =
          this.maxHeight;

        this.selectedRows = this.$store.state.tempQuestions;
        this.selectedRowKeys = this.$store.state.tempQuestions.map((v) => v.id);
        this.isTemp = true;
        this.form.page = 0;
        this.form.tid = "";
        this.form.tagIds = "";
        this.form.name = "";
        this.pagination.current = 1;
      } else {
        this.isTemp = false;
      }
      this.componentKey += 1;

      this.$store.commit("updateIsKeepAlive", false);
      this.form.page = 0;
      this.form.tagIds = "";
      await this.getTagList();
      this.getQuestionList();
      this.getQuestionGroupList();
      this.$store.commit("delRefreshPath", "question");
    } else {
      this.isTemp = false;
      this.form.page = 0;
      this.form.name = "";
      this.form.tagIds = "";
      this.form.tid = "";

      this.getQuestionList();
    }
  },
};
</script>

<style scoped lang="scss">
.Question {
  border-radius: 16px;
  background-color: #fff;
  padding: 24px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  .ques-header {
    .ques-header-top {
      display: flex;
      align-items: center;
      .title {
        display: flex;
        align-items: center;
        font-weight: 500;
        color: #303030;
        .oblong {
          margin-right: 8px;
          width: 4px;
          height: 24px;
          background: #83ba7b;
        }
      }
      .btn {
        margin: 0 16px 0 30px;
        padding: 0 16px;
        height: 32px;
        background: #83ba7b;
        border-radius: 4px;
        text-align: center;
        line-height: 32px;
        color: #fff;
        cursor: pointer;
        &.group {
          margin: 0;
          background-color: #3298f1;
        }
      }
    }
    .ques-header-bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px 60px 24px 0;
      .ques-header-bottom-left {
        display: flex;
        align-items: center;

        .ant-form {
          display: flex;
          align-items: center;

          .ant-row {
            margin-right: 25px;
            margin-bottom: 0;
            display: flex;
            align-items: center;

            .ant-input,
            .ant-select {
              width: 240px;
              height: 30px;
            }
          }
        }
        .search {
          width: 72px;
          height: 32px;
          background: #83ba7b;
          border-radius: 4px;
          text-align: center;
          line-height: 32px;
          color: #fff;
          cursor: pointer;
        }
      }
      .ques-header-bottom-right {
        display: flex;
        align-items: center;
        div {
          width: 72px;
          height: 32px;
          line-height: 32px;
          border-radius: 4px;
          color: #fff;
          text-align: center;
          cursor: pointer;

          &.finish {
            margin-left: 16px;
          }
        }
      }
    }
  }
  .ques-main::v-deep {
    position: relative;
    flex: 1;
    .ant-table-wrapper {
      .ant-table-header {
        margin-bottom: -21px !important;
        height: 86px;
        background: #f2f8f1;
      }
      .ant-table-body {
        border-bottom: 1px solid #e8e8e8;
        // min-height: 424px;
      }
      .ant-table-body::-webkit-scrollbar {
        display: none; /* 对于Chrome, Safari和Opera */
      }
      .ant-pagination {
        height: 32px;
      }
      .text {
        color: #333 !important;
      }
      .tags {
        display: flex;
        justify-content: center;
        align-items: center;
        div:nth-child(n + 2) {
          margin-left: 5px;
        }
      }
      .tag-list {
        display: flex;
        flex-wrap: wrap;
        color: #fff;
        overflow: auto;
        div {
          padding: 0 20px;
          cursor: pointer;
          height: 46px;
          line-height: 46px;
          border-radius: 20px;
          box-sizing: border-box;
          &:nth-child(1) {
            width: 280px;
          }
          &:nth-child(n + 2) {
            margin-top: 12px;
          }

          &:nth-child(n + 2) {
            margin-left: 20px;
          }
          &:nth-child(2n) {
            margin-left: 40px;
          }
        }
      }
      .ant-select::v-deep {
        min-width: 130px;
        .ant-select-selection__rendered {
          display: flex;
          justify-content: center;
        }
      }

      .action {
        display: flex;
        align-self: center;
        div {
          margin: auto;
          width: 64px;
          height: 24px;
          border-radius: 4px;
          border: 1px solid #3298f1;
          color: #3298f1;
          cursor: pointer;
          &.del {
            margin-left: 10px;
            border-color: #dc3333;
            color: #dc3333;
          }
        }
      }
    }
    .tag-box {
      position: absolute;
      top: 65px;
      right: 0;
      padding: 20px;
      width: 300px;
      border: 1px solid #e8e8e8;
      background: #fff;
      border-top: none;
      // box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;
      color: #fff;
      overflow: auto;
      div {
        margin: 5px;
        padding: 0 20px;
        text-align: center;
        cursor: pointer;
        height: 46px;
        line-height: 46px;
        border-radius: 20px;
        box-sizing: border-box;
        &:nth-child(1) {
          width: 280px;
        }
        // &:nth-child(n + 2) {
        //   margin-top: 5px;
        //   margin-left: 20px;
        // }

        // &:nth-child(2n) {
        //   margin-left: 40px;
        // }
      }
    }
  }
  .handle-modal::v-deep {
    .ant-modal-header {
      .ant-modal-title {
        text-align: center !important;
      }
    }
    .ant-modal-body {
      text-align: center;
      span {
        color: #ec4e44;
      }
    }
    .ant-modal-footer {
      text-align: center;
      padding-bottom: 44px;
      border: 0;
      div button {
        width: 72px;
        height: 32px;
        &:nth-child(2) {
          margin-left: 80px;
          background: #83ba7b;
          border: 0;
        }
      }
    }
  }
}
</style>
